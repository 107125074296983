/* eslint-disable @typescript-eslint/no-unused-vars */

function call(method: any) {
  return fetch("/rpc", {
    method: "POST",
    body: JSON.stringify({ ...method, jsonrpc: "2.0", id: 0 }),
    headers: {
      "content-type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((json) =>
      json.error == null ? json.result : Promise.reject(json.error)
    );
}

export function ingredientAutocomplete(params: { input: string, ignore: Array<string> }): Promise<Array<string>> {
  return call({
    method: "ingredientAutocomplete",
    params: params,
  }) as Promise<Array<string>>;
}

export function findRecipes(params: { ingredients: Array<string> }): Promise<Array<({ name: string, url: string, ingredients: Array<string>, score: number } & Partial<{ image: string }>)>> {
  return call({
    method: "findRecipes",
    params: params,
  }) as Promise<Array<({ name: string, url: string, ingredients: Array<string>, score: number } & Partial<{ image: string }>)>>;
}

export function getStats(): Promise<{ nbrPerSource: { [K in string]: number }, nbrPerIngredient: { [K in string]: number } }> {
  return call({
    method: "getStats",
    params: {},
  }) as Promise<{ nbrPerSource: { [K in string]: number }, nbrPerIngredient: { [K in string]: number } }>;
}
