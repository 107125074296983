import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { useMethod } from "react-farcry";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";

import "./App.css";

import { findRecipes, ingredientAutocomplete, getStats } from "./rpc-client";

function isDebug() {
  return window.location.hash.indexOf("#debug") !== -1;
}

function Navbar() {
  return (
    <nav className="navbar navbar-inverse navbar-fixed-top navbar-dark">
      <Link to="/" className="navbar-brand">
        Receptjakt
      </Link>
      <ul className="navbar-nav mr-auto">
        <li className="nav-item">
          <Link to="/stats" className="nav-link">
            Stats
          </Link>
        </li>
      </ul>
    </nav>
  );
}

interface RecipeEntryProps {
  recipe: {
    name: string;
    url: string;
    image?: string;
    ingredients: string[];
    score: number;
  };
  inputIngredients: string[];
  onClickMissingIngredient: (ingredient: string) => void;
}

function RecipeEntry(props: RecipeEntryProps) {
  const { recipe } = props;
  const { url } = recipe;

  const missing = props.inputIngredients.filter(
    (ingredient) => !recipe.ingredients.includes(ingredient)
  );

  const nonMatching = recipe.ingredients.filter(
    (x) => !props.inputIngredients.includes(x)
  );

  return (
    <div className="recipe-entry">
      <a href={url} target="_new">
        <div className="thumb-container">
          {recipe.image != null ? (
            <img src={recipe.image} className="blurry" />
          ) : (
            <div className="missing-thumb">Bild saknas</div>
          )}
        </div>
      </a>
      <div style={{ marginLeft: "1em" }}>
        <span style={{ fontSize: "small" }}>{new URL(url).hostname}</span>
        <a href={url} target="_new">
          <h5>
            {recipe.name} {isDebug() ? ` (${recipe.score.toFixed(2)})` : null}
          </h5>
        </a>
        {missing.length > 0 &&
          missing.map((ingredient) => (
            <span
              style={{
                marginRight: "0.75em",
                textDecoration: "line-through",
              }}
            >
              {ingredient}
            </span>
          ))}
        {nonMatching.length === 0 ? null : (
          <div>
            <FontAwesomeIcon size={"sm"} icon={faPlus} />{" "}
            {nonMatching.map((ingredient) => (
              <span
                className="non-matching-ingredient"
                onClick={() => props.onClickMissingIngredient(ingredient)}
              >
                {ingredient}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

interface SuggestionsProps {
  suggestions: string[];
  onClick: (suggestion: string) => void;
}

function Suggestions(props: SuggestionsProps) {
  const { suggestions } = props;

  if (suggestions.length === 0) {
    return null;
  }

  return (
    <>
      <div
        style={{ marginTop: "1.2em", fontWeight: "bold", fontSize: "1.2em" }}
      >
        Ingredienser
      </div>
      <ul className="suggestions">
        {props.suggestions.map((suggestion) => (
          <li
            onClick={() => {
              props.onClick(suggestion);
            }}
          >
            {suggestion}
          </li>
        ))}
      </ul>
    </>
  );
}

interface IngredientTagProps {
  ingredient: string;
  onClick: (ingredient: string) => void;
}

function IngredientTag(props: IngredientTagProps) {
  const { ingredient } = props;
  return (
    <span
      key={ingredient}
      className="ingredient-tag"
      onClick={() => props.onClick(ingredient)}
    >
      <FontAwesomeIcon size="sm" icon={faTimes} /> {ingredient}
    </span>
  );
}

function MainContent() {
  const [input, setInput] = useState("");
  const [ingredients, setIngredients] = useState<string[]>([]);
  const suggestions = useMethod(
    ingredientAutocomplete,
    {
      input,
      ignore: ingredients,
    },
    { reflectFetching: true }
  );

  function removeIngredient(ingredient: string) {
    setIngredients((old) => old.filter((x) => x !== ingredient));
  }

  const recipes = useMethod(findRecipes, { ingredients });
  const inputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleClickMissingIngredient = useCallback((ingredient: string) => {
    setIngredients((old) => [...old, ingredient]);
  }, []);

  return (
    <div
      className="container"
      style={{ marginTop: "2em", marginBottom: "1em" }}
    >
      <div className="row">
        <div className="col-12">
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (suggestions != null && suggestions.length > 0) {
                  setIngredients((old) => [...old, suggestions[0]]);
                  setInput("");
                  inputRef.current?.focus();
                }
              }}
            >
              <input
                ref={inputRef}
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Skriv in en ingrediens du har hemma eller är sugen på"
                style={{
                  padding: "0.75em",
                  width: "100%",
                  display: "block",
                  outline: "none",
                }}
              />
            </form>
          </div>

          {ingredients.length > 0 && (
            <div className="ingredients">
              {ingredients.map((ingredient) => (
                <IngredientTag
                  ingredient={ingredient}
                  onClick={() => removeIngredient(ingredient)}
                />
              ))}
            </div>
          )}

          {input === "" ? null : suggestions == null ? null : (
            <Suggestions
              suggestions={suggestions}
              onClick={(suggestion: string) => {
                setIngredients((old) => [...old, suggestion]);
                setInput("");
              }}
            />
          )}

          {input.length === 0 ? (
            <div style={{ marginTop: "0.5em" }}>
              {recipes?.map((recipe) => (
                <RecipeEntry
                  key={recipe.url}
                  recipe={recipe}
                  inputIngredients={ingredients}
                  onClickMissingIngredient={handleClickMissingIngredient}
                />
              ))}
            </div>
          ) : suggestions?.length === 0 ? (
            <div>Ingen träff på "{input}"</div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function Stats() {
  const stats = useMethod(getStats);
  if (stats == null) {
    return null;
  }

  const { nbrPerSource, nbrPerIngredient } = stats;
  const sources = Object.keys(nbrPerSource);
  const total = sources
    .map((source) => nbrPerSource[source])
    .reduce((a, b) => a + b);

  return (
    <div style={{ display: "flex" }}>
      <table className="stats sources">
        <thead>
          <tr>
            <th>Source</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {sources.map((source) => (
            <tr>
              <td>{source}</td>
              <td>{nbrPerSource[source]}</td>
            </tr>
          ))}
          <tr>
            <td>TOTAL</td>
            <td>{total}</td>
          </tr>
        </tbody>
      </table>
      <table className="stats">
        <thead>
          <tr>
            <th>Ingredient</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(nbrPerIngredient).map((ingredient) => (
            <tr>
              <td>{ingredient}</td>
              <td>{nbrPerIngredient[ingredient]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Switch>
          <Route path="/stats">
            <Stats />
          </Route>
          <Route path="/">
            <MainContent />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
